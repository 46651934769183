import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = ({ title, description, keywords, author, imageUrl, url, noIndex, schema }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />
      {noIndex && <meta name="robots" content="noindex, nofollow" />}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={url} />
      <meta name="twitter:card" content="summary_large_image" />
      {schema && <script type="application/ld+json">{JSON.stringify(schema)}</script>}
    </Helmet>
  );
};

MetaTags.defaultProps = {
  title: 'Wallfleur',
  description: 'Default Description',
  keywords: 'ecommerce, shop, online store',
  author: 'Wallfleur',
  imageUrl: 'Default Image URL',
  url: 'https://www.wallfleurthings.com',
  noIndex: false,
  schema: null
};

export default MetaTags;
