import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

const Table = ({ headers, rows, user }) => {
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortColumn, setSortColumn] = useState(headers[0]);
    const [sortedRows, setSortedRows] = useState(rows);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modalContent, setModalContent] = useState({ description: '', _id: '', email: '' });

  useEffect(() => {
    setSortedRows(rows);
  }, [rows]);

  const handleSort = (column) => {
    const newSortOrder = sortColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    setSortColumn(column);

    const columnIndex = headers.indexOf(column);
    const sorted = [...rows].sort((a, b) => {
      const aValue = a[columnIndex];
      const bValue = b[columnIndex];

      if (typeof aValue === 'string') {
        return newSortOrder === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else if (typeof aValue === 'number') {
        return newSortOrder === 'asc'
          ? aValue - bValue
          : bValue - aValue;
      }
      return 0;
    });

    setSortedRows(sorted);
  };

  const openModal = (id, email) => {
    setModalContent({ ...modalContent, _id: id, email });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent({ description: '', _id: '', email: '' });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}support-reply`, JSON.stringify(modalContent), {
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "application/json"
        },
         });
        if (response.status === 200) {
            setIsLoading(true);
            toast.success(response.data.message, { position: 'top-right' });
            window.location.reload();
        }else {
            setIsLoading(false);
            toast.error(response.data.message, { position: 'top-right' });
        }
    } catch (error) {
        console.error('Error sending reply:', error);
    }
  };

  return (
    <div style={{ overflowX: 'auto', width: '90%' }}>
      <table className='table-data'>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                className={index < 2 ? 'fixed-column' : ''}
                onClick={() => handleSort(header)}
                style={{ cursor: 'pointer' }}
              >
                {header} {sortColumn === header ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedRows.map((contact, rowIndex) => (
            <tr key={rowIndex}>
              {contact.map((cellData, cellIndex) => {
                if (cellIndex === 0) {
                  return (
                    <td key={cellIndex} className={cellIndex < 2 ? 'fixed-column' : ''}>
                      {rowIndex + 1}
                    </td>
                  );
                } else if (headers[cellIndex] === 'Reply') {
                  return (
                    <td key={cellIndex} className={cellIndex < 2 ? 'fixed-column' : ''}>
                      <button className='reply_btn' onClick={() => openModal(contact[0], contact[2])}>
                        <img width='30px' height='30px' src='https://wallfleur-images.s3.ap-south-1.amazonaws.com/common/edit.svg' alt='edit_icon' />
                      </button>
                    </td>
                  );
                } else {
                  return (
                    <td key={cellIndex} className={cellIndex < 2 ? 'fixed-column' : ''}>
                      {cellData}
                    </td>
                  );
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content reply_modal">
            <h2>Add Reply</h2>
            <textarea
                value={modalContent.description}
                onChange={(e) => setModalContent({ ...modalContent, description: e.target.value })}
                placeholder="Write your description here..."
            />
            <button onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? 'Replying...' : 'Reply'}
            </button>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;
