import React, { useEffect, useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authcontext';
import Navbar from '../../components/Navbar';
import Leftnav from '../../components/Leftnav';
import Table from '../advertisement/AdvertisementTable';
import SkeletonTable from '../TableSkeleton';
import axios from 'axios';
import {COMMON} from '../../constants/constant';


const headers = ['Id', 'Name', 'Status', 'Added Date', 'Last updated', 'Action'];

const Advertisement = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [totalAdvertisement, setTotalAdvertisement] = useState(0);
  const [advertisements, setAdvertisement] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if(!user){
      navigate('/');
    }else{
      const fetchAdvertisement = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}manage-advertisement`, {
            params: {
              page: currentPage,
              limit: 10
            },
            headers: {
              "Authorization": `Bearer ${user}`,
              "Content-Type": "application/json"
          },
          });
          setAdvertisement(response.data.advertisement);
          setTotalAdvertisement(response.data.totalAdvertisement);
          setLoading(false);
        } catch (error) {
          console.error("There was an error fetching the Categories!", error);
          setError(error);
          setLoading(false);
        }
      };

      fetchAdvertisement();
    }
  }, [currentPage]);

  const deleteAdvertisement = async (_id) => {
    if (!window.confirm('Are you sure you want to delete this Category?')) {
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}manage-delete-api`, { _id, database: 'advertisement' }, {
        headers: {
            "Authorization": `Bearer ${user}`,
            "Content-Type": "application/json"
        },
      });
      alert('Advertisement deleted successfully');
      window.location.reload();
    } catch (error) {
      alert('Error deleting Category:', error.message);
    }
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= Math.ceil(totalAdvertisement / 10)) {
      setCurrentPage(page);
    }
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const totalPages = Math.ceil(totalAdvertisement / 10);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const rows = advertisements.map(advertisement => [
    advertisement._id,
    advertisement.banner_image,
    // advertisement.banner_url,
    advertisement.status === 1 ? 'Active' : 'Inactive',
    formatDate(advertisement.created_date),
    formatDate(advertisement.updated_date),
    'Action'
  ]);

  return (
    <>
      <Navbar />
      <Leftnav />
      <div className="row-container">
        <div className='page_title'>
          <h1 className="title">Advertisements List</h1>
          <Link className='add_btn' to="/advertisement/add-advertisement">ADD</Link>
        </div>
        {loading ? (
          <SkeletonTable />
        ) :(
          <>
            <Table headers={headers} rows={rows} deleteAdvertisement={deleteAdvertisement} />
            <div className='footer'>
              <div className='total_rows'>Total Advertisements: {totalAdvertisement}</div>
              <div className='pagination'>
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>&lt;</button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>&gt;</button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Advertisement;
