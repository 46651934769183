import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode'; // Make sure this is imported correctly

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(() => {
    const storedUser = Cookies.get('manage-user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const login = async (userData) => {
    try {
      setUser(userData);
      Cookies.set('manage-user', JSON.stringify(userData), { expires: 0.5 });
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const logout = async () => {
    if (!user) return;
    try {
      Cookies.remove('manage-user');
      setUser(null);
      console.log("Logged out successfully.");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
