import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/authcontext';
import axios from 'axios';
import { downloadBulkInvoices } from '../../utils/bulkinvoice';
import { downloadBulkShipping } from '../../utils/bulkshipping';

const Table = ({ headers, rows, deleteProduct, fetchSearchData }) => {
  const { user } = useAuth();
  const [customerNameSearch, setCustomerNameSearch] = useState('');
  const [orderIdSearch, setOrderIdSearch] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortColumn, setSortColumn] = useState(headers[0]);
  const [sortedRows, setSortedRows] = useState(rows);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [filterType, setFilterType] = useState('date');
  const [rangeFrom, setRangeFrom] = useState('');
  const [rangeTo, setRangeTo] = useState('');
  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isInvoiceDownload, setIsInvoiceDownload] = useState(true);

  useEffect(() => {
    setSortedRows(rows);
  }, [rows]);

  const handleCustomerNameSearchChange = (event) => {
    fetchSearchData(event.target.value)
    setCustomerNameSearch(event.target.value);
    setOrderIdSearch('');
  };

  const handleOrderIdSearchChange = (event) => {
    fetchSearchData(event.target.value);
    setOrderIdSearch(event.target.value);
    setCustomerNameSearch('');
  };

  const handleSort = (column) => {
    const newSortOrder = sortColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    setSortColumn(column);

    const columnIndex = headers.indexOf(column);
    const sorted = [...rows].sort((a, b) => {
      const aValue = a[columnIndex];
      const bValue = b[columnIndex];

      if (typeof aValue === 'string') {
        return newSortOrder === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else if (typeof aValue === 'number') {
        return newSortOrder === 'asc'
          ? aValue - bValue
          : bValue - aValue;
      }
      return 0;
    });

    setSortedRows(sorted);
  };


  // const filteredRows = sortedRows.filter(row => {
  //   const customerNameMatch = customerNameSearch ? row[1]?.toString().toLowerCase().includes(customerNameSearch.toLowerCase()) : true;
  //   const orderIdMatch = orderIdSearch ? row.find(item => item?.toString().toLowerCase().includes(orderIdSearch.toLowerCase())) : true;
  //   return customerNameMatch && orderIdMatch;
  // });

  const toggleModal = (isInvoice = true) => {
    setIsInvoiceDownload(isInvoice);
    setIsModalOpen(!isModalOpen);
  };

  const toggleExportModal = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };

  const handleDownload = async () => {
    if (!rangeFrom || !rangeTo || !status) {
      alert('Please fill in all fields.');
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}download-order`, {
        params: {
          filterType,
          rangeFrom,
          rangeTo,
          status
        },
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "application/json"
      },
      });

      const orders = response.data.orders;
      if (!Array.isArray(orders) || orders.length === 0) {
        alert('No orders found for the specified range and status.');
        return;
      }

      if (isInvoiceDownload) {
        await downloadBulkInvoices(orders);
      } else {
        await downloadBulkShipping(orders);
      }

      toggleModal();
    } catch (error) {
      console.error('Error during download:', error);
      alert('An error occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleExportOrder = async () => {
    if (!rangeFrom || !rangeTo || !status) {
      alert('Please fill in all fields.');
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}export-order-list`, {
        params: {
          rangeFrom,
          rangeTo,
          status
        },
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "application/json"
      },
      });

      const orders = response.data.orders;
      if (!Array.isArray(orders) || orders.length === 0) {
        alert('No orders found for the specified range and status.');
        return;
      }

      const exportData = orders.map(order => {
        const productNames = order.products.map(product => product.name).join(", ");
        const quantities = order.products.map(product => product.quantity).join(", ");
        
        return {
          orderId: order.order_id,
          customerName: order.customer_name,
          address: order.address,
          city: order.city,
          state: order.state,
          country: order.country,
          postalcode: order.postalCode,
          currency: order.currency,
          dialcode: order.dialcode,
          ordered_date: order.ordered_date,
          amount: order.amount,
          productNames: productNames,
          quantities: quantities 
        };
      }).flat(); 
      
      const worksheet = XLSX.utils.json_to_sheet(exportData);
  
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders');
  
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      const fileName = `orders_${new Date().toISOString()}.xlsx`;
  
      const link = document.createElement('a');
      link.href = URL.createObjectURL(data);
      link.download = fileName;
      link.click();

      toggleExportModal();  
    } catch (error) {
      console.error('Error during download:', error);
      alert('An error occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ overflowX: 'auto', width: '90%' }}>
      <div className='search-container'>
        <input
          type='text'
          value={customerNameSearch}
          onChange={handleCustomerNameSearchChange}
          placeholder='Search by Customer Name / Email'
        />
        <input
          type='text'
          value={orderIdSearch}
          onChange={handleOrderIdSearchChange}
          placeholder='Search by Order ID'
        />
        <button onClick={() => toggleModal(true)} className="invoice-btn">Invoice</button>
        <button onClick={() => toggleModal(false)} className="invoice-btn">Shipping</button>
        <button onClick={() => toggleExportModal()} className="invoice-btn">Export</button>
      </div>
      <table className='table-data'>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                className={index < 2 ? 'fixed-column' : ''}
                onClick={() => handleSort(header)}
                style={{ cursor: 'pointer' }}
              >
                {header} {sortColumn === header ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedRows.map((order, rowIndex) => (
            <tr key={rowIndex}>
              {order.slice(0, -1).map((cellData, cellIndex) => {
                if (cellIndex === 0) {
                  return (
                    <td key={cellIndex} className={cellIndex < 2 ? 'fixed-column' : ''}>
                      {rowIndex + 1}
                    </td>
                  );
                } else if (headers[cellIndex] === 'Action') {
                  return (
                    <td key={cellIndex} className={cellIndex < 2 ? 'fixed-column' : ''}>
                      <Link className='edit_btn' to={`/orders/order-detail/${order[0]}`}><img width='30px' height='30px' src='https://wallfleur-images.s3.ap-south-1.amazonaws.com/common/edit.svg' alt='edit_icon' /></Link>
                      <button className='delete_btn' onClick={() => deleteProduct(order[0])}><img width='30px' height='30px' src='https://wallfleur-images.s3.ap-south-1.amazonaws.com/common/delete.png' alt='delete_icon' /></button>
                    </td>
                  );
                } else {
                  return (
                    <td key={cellIndex} className={cellIndex < 2 ? 'fixed-column' : ''}>
                      {cellData}
                    </td>
                  );
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>{isInvoiceDownload ? 'Invoices' : 'Shipping'}</h3>
            <div>
              <label>Download By:</label>
              <select value={filterType} onChange={(e) => setFilterType(e.target.value)}>
                <option value="date">Date</option>
                <option value="_id">ID</option>
              </select>
            </div>
            <div>
              <label>From:</label>
              <input
                type={filterType === 'date' ? 'date' : 'text'}
                value={rangeFrom}
                onChange={(e) => setRangeFrom(e.target.value)}
                placeholder={filterType === 'date' ? 'YYYY-MM-DD' : 'Order ID'}
              />
            </div>
            <div>
              <label>To:</label>
              <input
                type={filterType === 'date' ? 'date' : 'text'}
                value={rangeTo}
                onChange={(e) => setRangeTo(e.target.value)}
                placeholder={filterType === 'date' ? 'YYYY-MM-DD' : 'Order ID'}
              />
            </div>
            <div>
              <label>Status:</label>
              <select value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value="">Pending</option>
                <option value="paid">Payment done</option>
                <option value="crafting">Crafting</option>
                <option value="Shipped">Shipped</option>
                <option value="Delivered">Delivered</option>
                <option value="Delayed">Delayed</option>
              </select>
            </div>
            <div>
              <button onClick={handleDownload} disabled={isLoading}>
                {isLoading ? 'Downloading...' : 'Download'}
              </button>
              <button onClick={toggleModal}>Close</button>
            </div>
          </div>
        </div>
      )}

      {isExportModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>Export Order List</h3>
            <div>
              <label>From:</label>
              <input
                type='date'
                value={rangeFrom}
                onChange={(e) => setRangeFrom(e.target.value)}
                placeholder='YYYY-MM-DD'
              />
            </div>
            <div>
              <label>To:</label>
              <input
                type='date'
                value={rangeTo}
                onChange={(e) => setRangeTo(e.target.value)}
                placeholder='YYYY-MM-DD'
              />
            </div>
            <div>
              <label>Status:</label>
              <select value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value="">Pending</option>
                <option value="paid">Payment done</option>
                <option value="crafting">Crafting</option>
                <option value="Shipped">Shipped</option>
                <option value="Delivered">Delivered</option>
                <option value="Delayed">Delayed</option>
              </select>
            </div>
            <div>
              <button onClick={handleExportOrder} disabled={isLoading}>
                {isLoading ? 'Exporting...' : 'Export'}
              </button>
              <button onClick={toggleExportModal}>Close</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;
