import React from 'react';
import '../assets/css/skeleton.css';

const SkeletonTable = ({ rows = 10, columns = 6 }) => {
  return (
    <div className="skeleton-table">
      {Array.from({ length: rows }).map((_, rowIndex) => (
        <div className="skeleton-row" key={rowIndex}>
          {Array.from({ length: columns }).map((_, cellIndex) => (
            <div className="skeleton-cell" key={cellIndex}></div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default SkeletonTable;
