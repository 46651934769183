import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const LeftNav = () => {
    const location = useLocation();

    return (
        <>
            <div className="left-nav">
                <Link className={`left-nav-link ${location.pathname === '/dashboard' ? 'active' : ''}`} to="/dashboard">Dashboard</Link>
                <Link className={`left-nav-link ${location.pathname.includes('/orders') ? 'active' : ''}`} to="/orders">Orders</Link>
                <Link className={`left-nav-link ${location.pathname.includes('/products') ? 'active' : ''}`} to="/products">Products</Link>
                <Link className={`left-nav-link ${location.pathname.includes('/category') ? 'active' : ''}`} to="/category">Category</Link>
                <Link className={`left-nav-link ${location.pathname.includes('/customers') ? 'active' : ''}`} to="/customers">Customers</Link>
                <Link className={`left-nav-link ${location.pathname.includes('/advertisement') ? 'active' : ''}`} to="/advertisement">Advertisement</Link>
                <Link className={`left-nav-link ${location.pathname.includes('/newsletter') ? 'active' : ''}`} to="/newsletter">Newsletter</Link>
                <Link className={`left-nav-link ${location.pathname.includes('/contact-us') ? 'active' : ''}`} to="/contact-us">Contact Us</Link>
            </div>
        </>
    );
};

export default LeftNav;
