import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authcontext';
import Navbar from '../../components/Navbar';
import Leftnav from '../../components/Leftnav';
import axios from 'axios';
import { ADVERTISEMENT } from '../../constants/constant';
import { toast } from 'react-toastify';


function AddAdvertisement() {
  const { adId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [bannerUrl, setBannerUrl] = useState('');
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    if (!user) {
      navigate('/');
    } else if (adId) {
      fetchAdvertisementData(adId);
    }
  }, [adId]);

  const fetchAdvertisementData = async (adId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}advertisement-detail/${adId}`, {
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "application/json"
        },
      });
      const adData = response.data[0];
      setBannerUrl(adData.banner_url);
      setStatus(adData.status);
      setImagePreview(`${ADVERTISEMENT}${adData.banner_image}`);

    } catch (error) {
      console.error('Error fetching advertisement data:', error);
    }
  };

  const handleUrlChange = (event) => {
    setBannerUrl(event.target.value);
  };

  const handleCheckboxChange = () => {
    setStatus(!status);
  };

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setSelectedImage(imageFile);
      setImagePreview(reader.result);
    };

    if (imageFile) {
      reader.readAsDataURL(imageFile);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('id', adId || '');
    formData.append('banner_url', bannerUrl);
    formData.append('status', status ? 1 : 0);
    if (selectedImage) {
        formData.append('banner_image', selectedImage.name);
        formData.append('image', selectedImage);
    }

    setLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}add-advertisement`, formData, {
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "multipart/form-data"
        },
      });

      if (response.status === 201 || response.status === 200) {
        toast.success('Advertisement saved successfully!', { position: 'top-right' });
        navigate('/advertisement');
      } else {
        toast.success('Failed to save advertisemen!', { position: 'top-right' });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);  
      console.error('Error:', error);
      toast.success('Error saving advertisement!', { position: 'top-right' });
    }
  };

  return (
    <>
      <Navbar />
      <Leftnav />
      <div className="row-container">
        <div className="page_title">
          <h1 className="title">{adId ? 'Edit Advertisement' : 'Add Advertisement'}</h1>
        </div>
        <form className="advertisement_form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="imageUpload">Advertisement Image*:</label>
            <input
              type="file"
              id="imageUpload"
              onChange={handleImageChange}
            />
            {imagePreview && (
              <img
                src={imagePreview}
                alt="Preview"
                style={{ width: '1200px', height: '300px', marginTop: '10px', marginBottom: '20px' }}
              />
            )}
          </div>
          <div className="form-group">
            <label htmlFor="bannerUrl">Advertisement URL*:</label>
            <input
              type="text"
              id="bannerUrl"
              value={bannerUrl}
              placeholder="Advertisement URL"
              onChange={handleUrlChange}
            />
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={status}
                onChange={handleCheckboxChange}
              />
            Status
            </label>
          </div>
            <button type="submit" disabled={loading}>
                {adId ? (loading ? "Updating..." : "Update") : (loading ? "Submitting..." : "Submit")}
            </button>
        </form>
      </div>
    </>
  );
}

export default AddAdvertisement;
