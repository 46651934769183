import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import fragile from '../assets/img/fragile.jpeg';

export const downloadShippingLable = (customerDetails, order_id) => {

    const shippingHtml = `<div style="width: 900px; padding:50px;display:flex;justify-content:center;">
    <div style="width: 100%; height:100%; border: 2px solid black; padding: 20px; border-radius: 15px; font-family: Arial, sans-serif;">
        <table style="width: 100%;height:280px;padding:15px 0px;">
            <tr>
            <td width=35% style="font-weight: bold;font-size: 34px;text-align: center;">SHIP FROM:</td>
            <td width=65% style="font-weight: bold;font-size: 34px;">
                    WALLFLEUR<br>
                    H/no.-76 Forest Colony<br>
                    Dimapur, 797112,<br>
                    Nagaland, India.<br>
                    Phone no. : +916391113851.
                </td>
            </tr>
        </table>

        <hr style="border: none; border-top: 1px solid black; margin: 10px 0;">

        <table style="width: 100%;padding:15px 0px;height:600px;">
            <tr>
                <td width=35% style="text-align: center;">
                    <img src="${fragile}" alt="fragile" style="width:300px; margin-left:-20px;margin-right:20px;"/>
                </td>
                <td width="65%" style="font-size: 34px; font-weight: bold;">
                    SHIP TO:<br><br>
                    ${customerDetails.name}<br><br>
                    ${customerDetails.address},<br>
                    ${customerDetails.city}, ${customerDetails.state},<br>
                    ${customerDetails.country} ${customerDetails.pincode ? ', ' + customerDetails.pincode : ''}.<br><br>
                    Phone no.: ${customerDetails.dialcode}${customerDetails.phoneNo}.<br>
                </td>

            </tr>
        </table>

        <hr style="border: none; border-top: 1px solid black; margin: 10px 0;">

        <table style="width: 100%;padding:10px 0px 20px 0px;height:180px;">
            <tr style="background-color:#fff !important;">
                <td width=35% style="font-weight: bold; font-size: 34px;">WEIGHT :</td>
                <td width=65% style="font-size: 34px;">              </td>
            </tr>
        </table>

        <div style="text-align: center; margin-top: 20px; font-size: 34px;">
            www.wallfleurthings.com
        </div>
    </div>
    </div>`;

    const hiddenContainer = document.createElement('div');
    hiddenContainer.style.position = 'fixed';
    hiddenContainer.style.top = '-10000px';
    hiddenContainer.innerHTML = shippingHtml;
    document.body.appendChild(hiddenContainer);

    html2canvas(hiddenContainer).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210; // Width of the PDF in mm
        const pageHeight = 295; // Height of the PDF in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft > 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
        }

        pdf.save(`shipping_label_${customerDetails.name}.pdf`);

        document.body.removeChild(hiddenContainer);
    }).catch(error => {
        console.error("Error generating PDF:", error);
    });
};
