import React from 'react';

const Card = ({ cards }) => {
  return (
    <div className="card-container">
      <div className="card-grid">
      {cards.map((card, index) => (
          <div key={index} className="card">
            <h2>{card.title}</h2>
            <p style={{ display: 'inline-block' }}>{card.content}</p>
            <a href={card.link} style={{ display: 'inline-block', marginLeft: '10px' }}>More Info</a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Card;
