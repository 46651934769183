import React from 'react';
import LoginForm from '../components/LoginForm';
import Logo from '../assets/img/Logo.png';

const Login = () => {
  return (
    <div className='login'>
        <div className="loginform">
            <img src={Logo} style={{"height":"60px","width":"140px","marginLeft":"20px",'marginBottom':'20px'}} alt="Logo"/>
            <LoginForm/>
        </div>
    </div>
  )
}

export default Login