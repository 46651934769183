import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams,useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Leftnav from '../../components/Leftnav';
import { useAuth } from '../../context/authcontext';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import { toast } from 'react-toastify';

function Addcustomer() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { customerId } = useParams();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dialcode, setDialCode] = useState('');
  const [address1, setAddress1] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const itiRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if(!user){
      navigate('/');
    }else{
      if (customerId) {
        fetchCategoryData(customerId);
      } else {
        resetFormFields();
      }
    }
  }, [customerId]);

  useEffect(() => {
    if (inputRef.current) {
      itiRef.current = intlTelInput(inputRef.current, {
        initialCountry: 'in',
        preferredCountries: ['in', 'us'],
        separateDialCode: true,
        utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
      });
      if(dialcode){
        itiRef.current.setNumber(dialcode+phoneNumber);
      }
    }
    return () => {
      if (itiRef.current) {
        itiRef.current.destroy();
      }
    };
  }, [inputRef.current]);
  
  const fetchCategoryData = async (customerId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}customer-detail/${customerId}`,{
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "application/json"
        },
      });
      if (response.ok) {
        const customerdata = await response.json();
        const fullName = customerdata[0].name.trim(); 
        const spaceIndex = fullName.indexOf(' '); 
        let firstName, lastName;
      
        if (spaceIndex !== -1) {
          firstName = fullName.substring(0, spaceIndex); 
          lastName = fullName.substring(spaceIndex + 1); 
        } else {
          firstName = fullName;
          lastName = ''; 
        }

        setFirstName(firstName);
        setLastName(lastName);
        setEmail(customerdata[0].email);
        setPhoneNumber(customerdata[0].phone);
        setAddress1(customerdata[0].address_1);
        setDialCode(customerdata[0].dialcode);
        setCountry(customerdata[0].country);
        setState(customerdata[0].state);
        setCity(customerdata[0].city);
        setPinCode(customerdata[0].pinCode);
        setIsChecked(customerdata[0].status);
      } else {
        console.error('Failed to fetch category data');
      }
    } catch (error) {
      console.error('Error fetching category data:', error);
    }
  };
  
  const resetFormFields = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setAddress1('');
    setCountry('');
    setState('');
    setCity('');
    setPinCode('');
    setIsChecked(false);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleAddress1Change = (event) => {
    setAddress1(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handlePinCodeChange = (event) => {
    setPinCode(event.target.value);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!firstName || !email || !address1 || !country || !state || !city || !pinCode || !isChecked) {
      setLoading(false);
      alert('Please fill out all required fields');
      return;
    }
    const dialCodeElement = document.getElementsByClassName('iti__selected-dial-code')[0];
    const dialCode = dialCodeElement ? dialCodeElement.innerText : '+91';
    const iti = itiRef.current;

    if (!phoneNumber) {
        setLoading(false);
        alert('Phone Number is required');
        return;
    } else if (!iti.isValidNumber()) {
        setLoading(false);
        alert('Invalid phone number');
        return;
    } else {
      setDialCode(dialCode);
    }

    const categoryData = {
      id: customerId || '',
      name: `${firstName} ${lastName || ''}`,
      phone: phoneNumber,
      dialcode: dialCode,
      email: email,
      address_1: address1,
      country: country,
      state: state,
      city: city,
      pinCode: pinCode,
      status: isChecked ? 1 : 0
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}addCustomer`, {
        method: 'POST',
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(categoryData),
      });
      if (response.ok) {
        toast.success('Customer added successfully!', { position: 'top-right' });
        navigate('/customers');
      } else {
        toast.error('Failed to add Customer', { position: 'top-right' });
      }
    } catch (error) {
      console.error('Error:', error);
        toast.error('Error adding Customer', { position: 'top-right' });
    }
    setLoading(false);
  };

  return (
    <>
        <Navbar />
        <Leftnav />
        <div className="row-container">
        <div className='page_title'>
            <h1 className="title">Add Customer</h1>
        </div>
        <form className='customer_form' onSubmit={handleSubmit}>
        <div className='form-container'>
            <div className='form-group'>  
                  <label htmlFor="firstName">First Name*:</label>
                  <input
                  type="text"
                  id="firstName"
                  value={firstName}
                  placeholder='First Name'
                  onChange={handleFirstNameChange}
                  required
                  />
                  </div>
                  <div className='form-group'>  
                  <label htmlFor="lastName">Last Name*:</label>
                  <input
                  type="text"
                  id="lastName"
                  value={lastName}
                  placeholder='Last Name'
                  onChange={handleLastNameChange}
                  />
              </div>
        </div>
        <div className='form-container'>
            <div className='form-group'>  
                      <label htmlFor="email">Email*:</label>
            <input
            type="email"
            id="email"
            value={email}
            placeholder='Email'
            onChange={handleEmailChange}
            required
            />
        </div>
        <div className='form-group'>  
            <label htmlFor="phoneNumber">Phone Number*:</label>
            <input
            type="tel"
            id="phoneNumber"
            ref={inputRef}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            required
            />
        </div>
        </div>
        <div>
            <label htmlFor="address1">Address 1:</label>
            <input
            type="text"
            id="address1"
            value={address1}
            placeholder='Address 1'
            onChange={handleAddress1Change}
            />
        </div>
        <div className='form-container'>
            <div className='form-group'>  
            <label htmlFor="country">Country:</label>
            <input
            type="text"
            id="country"
            value={country}
            placeholder='Country'
            onChange={handleCountryChange}
            />
        </div>
        <div className='form-group'>  
            <label htmlFor="state">State:</label>
            <input
            type="text"
            id="state"
            value={state}
            placeholder='State'
            onChange={handleStateChange}
            />
        </div>
        </div>
        <div className='form-container'>
            <div className='form-group'>  
                        <label htmlFor="city">City:</label>
            <input
            type="text"
            id="city"
            value={city}
            placeholder='City'
            onChange={handleCityChange}
            />
        </div>
        <div className='form-group'>  
            <label htmlFor="pinCode">Pin Code:</label>
            <input
            type="text"
            id="pinCode"
            value={pinCode}
            placeholder='Pin Code'
            onChange={handlePinCodeChange}
            />
        </div>
        </div>
        <div>
            <label>
                <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                />
                Status
            </label>
        </div>
        <button type="submit" disabled={loading}>
          {customerId ? (loading ? "Updating..." : "Update") : (loading ? "Submitting..." : "Submit")}
        </button>
        </form>
    </div>
    </>
  );
}

export default Addcustomer;
