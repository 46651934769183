import React, { useEffect, useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authcontext';
import Navbar from '../../components/Navbar';
import Leftnav from '../../components/Leftnav';
import Table from '../category/CategoryTable';
import axios from 'axios';
import SkeletonTable from '../TableSkeleton';
import {COMMON} from '../../constants/constant';


const headers = ['Id', 'Name', 'Added Date', 'Updated Date', 'Status', 'Action'];

const Category = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [totalCategory, setTotalCategory] = useState(0);
  const [categories, setCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if(!user){
      navigate('/');
    }else{
      const fetchCategories = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}manage-category`, {
            params: {
              page: currentPage,
              limit: 10
            },
            headers: {
              "Authorization": `Bearer ${user}`,
              "Content-Type": "application/json"
          },
          });
          setCategory(response.data.categories);
          setTotalCategory(response.data.totalCategory);
          setLoading(false);
        } catch (error) {
          console.error("There was an error fetching the Categories!", error);
          setError(error);
          setLoading(false);
        }
      };

      fetchCategories();
    }
  }, [currentPage]);

  const deleteCategory = async (id) => {
    if (!window.confirm('Are you sure you want to delete this Category?')) {
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}delete-api`, { id, database: 'category' }, {
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "application/json"
        },
      });
      alert('Category deleted successfully');
      window.location.reload();
    } catch (error) {
      alert('Error deleting Category:', error.message);
    }
  };

  const fetchSearchData = async (term) => {

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}search-category?search=${term}`,{
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "application/json"
        },
      });
      setCategory(response.data); 
    } catch (err) {
      console.error('Failed to fetch data.');
    } 
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= Math.ceil(totalCategory / 10)) {
      setCurrentPage(page);
    }
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const totalPages = Math.ceil(totalCategory / 10);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const rows = categories.map(category => [
    category.id,
    category.name,
    formatDate(category.added_date),
    formatDate(category.updated_date),
    category.status === 1 ? 'Active' : 'Inactive',
    'Action'
  ]);

  return (
    <>
      <Navbar />
      <Leftnav />
      <div className="row-container">
        <div className='page_title'>
          <h1 className="title">Category List</h1>
          <Link className='add_btn' to="/category/add-category">ADD</Link>
        </div>
        {loading ? (
          <>
            <div class="skeleton-table">
                <div class="skeleton-search">
                  <div class="skeleton-search-bar">
                  </div>
                </div>
            </div> 
            <SkeletonTable />
          </>
        ) :(
          <>
            <Table headers={headers} rows={rows} deleteCategory={deleteCategory} fetchSearchData={fetchSearchData} />
            <div className='footer'>
              <div className='total_rows'>Total Categories: {totalCategory}</div>
              <div className='pagination'>
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>&lt;</button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>&gt;</button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Category;
