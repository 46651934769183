import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/authcontext';
import axios from 'axios';
import SkeletonTable from '../../layouts/TableSkeleton';

const Card = ({ title, tableData }) => {
  return (
    <div className="table-card">
      <h2>{title}</h2>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              {tableData.headers.map(header => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.rows.map((row, index) => (
              <tr key={index}>
                {row.map((data, dataIndex) => (
                  <td key={dataIndex}>{data}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const CardContainer = () => {
  const { user } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [customers, setCustomer] = useState([]);
  const [orders, setOrders] = useState([]);
  const [orderLoading, setOrderLoading] = useState(true);
  const [customerLoading, setCustomerLoading] = useState(true);

useEffect(() => {
  setCustomerLoading(true);
  setOrderLoading(true);
  const fetchCustomers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}manage-customer?page=${currentPage}&limit=10`, {
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "application/json"
        }
      });
      setCustomer(response.data.customers);
    } catch (error) {
      console.error("There was an error fetching the customers!", error);
    }
    setCustomerLoading(false);
  };

  const fetchOrders = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}manage-orders?page=${currentPage}&limit=10`, {
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "application/json"
        }
      });
      setOrders(response.data.orders);
    } catch (error) {
      console.error("There was an error fetching the orders!", error);
    }
    setOrderLoading(false);
  };

  fetchCustomers();
  fetchOrders();
}, [user]);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const card1TableData = {
    headers: ['Customer','Amount','Status', 'Ordered Date'],
    rows: orders.map(order => [order.customer_name,order.amount || 0,order.status, formatDate(order.ordered_date)])
  };

  const card2TableData = {
    headers: ['Name', 'Added at'],
    rows: customers.map(customer => [customer.name, formatDate(customer.added_date)])
  };

  return (
    <div className="tablecard-container">
      {orderLoading ? (
        <SkeletonTable rows={9} columns={4} />
      ) :(
        <Card title="Latest Orders" tableData={card1TableData} />
      )}
      {customerLoading ? (
        <SkeletonTable rows={9} columns={2} />
      ) :(
        <Card title="New Customers" tableData={card2TableData} />
      )}
    </div>
  );
};

export default CardContainer;
