import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/authcontext';
import { toast } from 'react-toastify';

function LoginForm() {
  const { user,login } = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [user]);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}checkAdmin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password })
      });

      const data = await response.json();

      if (data.exists) {
        const { token } = data;
        login(token);
        toast.success('Logged in Successfully!', { position: 'top-right' });
        setTimeout(() => {
          navigate('/');
        }, 2000);
        setLoading(false);
        window.location.href = '/dashboard';
      } else {
        setError('Username or password is incorrect');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred while logging in');
    }
    setLoading(false);
    setUsername('');
    setPassword('');
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && <div className='error'>{error}</div>}
      <div>
        <input
          type="text"
          id="username"
          value={username}
          placeholder="Username"
          onChange={handleUsernameChange}
          required
        />
      </div>
      <div>
        <input
          type="password"
          id="password"
          value={password}
          placeholder="Password"
          onChange={handlePasswordChange}
          required
        />
      </div>
      <button type="submit" disabled={loading}>
        {loading ? 'Loggin in..' : 'Login'}
      </button>
    </form>
  );
}

export default LoginForm;
