import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authcontext';
import axios from 'axios';
import Navbar from '../../components/Navbar';
import Leftnav from '../../components/Leftnav';
import Table from './ProductTable';
import SkeletonTable from '../TableSkeleton';

const headers = ['Id', 'Name', 'Quantity', 'Price in INR', 'Price in USD', 'Added Date', 'Updated Date', 'Status', 'Action'];

const Products = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (!user) {
      navigate('/');
    } else {
      const fetchProducts = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}manage-product`, {
            params: {
              page: currentPage,
              limit: 10
            },
            headers: {
              "Authorization": `Bearer ${user}`,
              "Content-Type": "application/json"
            },
          });
          setProducts(response.data.products);
          setTotalProducts(response.data.totalProducts);
        } catch (error) {
          console.error("There was an error fetching the products!", error);
          setError(error);
        } finally {
          setLoading(false);
        }
      };
      fetchProducts();
    }
  }, [currentPage, user, navigate]);

  const deleteProduct = async (id) => {
    if (!window.confirm('Are you sure you want to delete this Product?')) {
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}delete-api`, { id, database: 'product' }, {
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "application/json"
        },
      });
      alert('Product deleted successfully');
      setProducts(products.filter(product => product.id !== id));
    } catch (error) {
      alert('Error deleting product:', error.message);
    }
  };

  const fetchSearchData = async (term) => {

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}search-product?search=${term}`,{
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "application/json"
        },
      });
      setProducts(response.data); 
    } catch (err) {
      console.error('Failed to fetch data.');
    } 
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= Math.ceil(totalProducts / 10)) {
      setCurrentPage(page);
    }
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const totalPages = Math.ceil(totalProducts / 10);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  // Create rows based on the products state
  const rows = products.map(product => ([
    product.id,
    product.name,
    product.quantity,
    product.inrprice,
    product.usdprice,
    formatDate(product.added_date),
    formatDate(product.updated_date),
    product.show_on_website === 1 ? 'Active' : 'Inactive',
    'Action'
  ]));



  return (
    <>
      <Navbar />
      <Leftnav />
      <div className="row-container">
        <div className='page_title'>
          <h1 className="title">Products List</h1>
          <Link className='add_btn' to="/products/add-product">ADD</Link>
        </div>
        {loading ? (
              <>
              <div class="skeleton-table">
                  <div class="skeleton-search">
                    <div class="skeleton-search-bar">
                    </div>
                  </div>
              </div> 
              <SkeletonTable />
            </>
            ) : (
          <>
            <Table headers={headers} rows={rows} deleteProduct={deleteProduct} fetchSearchData={fetchSearchData} />
            <div className='footer'>
              <div className='total_rows'>Total Products: {totalProducts}</div>
              <div className='pagination'>
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>&lt;</button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>&gt;</button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Products;
