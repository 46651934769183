import React from 'react';
import MetaTags from '../utils/MetaTags';
import { COMMON } from '../constants/constant';
import { useAuth } from '../context/authcontext';
import { useNavigate } from 'react-router-dom';


const Navbar = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <>
          <MetaTags 
        title="Manage Panel - Wallfleur" 
        description="Shop the latest products from a small artist with big dreams!"
        keywords="ecommerce, handmade products, small business"
      />
      <div className="navbar">
        <div className="nav-element">
          <img src={`${COMMON}Logo.png`} style={{ "height": "60px", "width": "140px", "marginLeft": "30px" }} alt="Logo" />
          <div className='profile'>
            Profile
            <div className="profile-option">
              <div className="profile-1" onClick={handleLogout}>Logout</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
