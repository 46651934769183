import './index.css';
import {BrowserRouter as Router,Routes,Route} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './context/authcontext';
import Dashboard from './layouts/Dashboard';
import Products from './layouts/product/Products';
import Category from './layouts/category/Category';
import Orders from './layouts/order/Orders';
import Customers from './layouts/customer/Customers';
import Login from './layouts/Login';
import AddCategory from './layouts/category/AddCategory';
import AddProduct from './layouts/product/AddProduct';
import AddCustomer from './layouts/customer/AddCustomer';
import OrderDetail from './layouts/order/OrderDetail';
import Advertisement from './layouts/advertisement/advertisement';
import AddAdvertisement from './layouts/advertisement/AddAdvertisement';
import Newsletter from './layouts/newsletter/Newsletter';
import ContactUs from './layouts/contactus/ContactUs';


function App() {
  return (
    <AuthProvider>
    <Router>
      <div>
      <ToastContainer />
        <Routes>
          <Route exact path='/' element={<Login/>}/>
          <Route exact path='/dashboard' element={<Dashboard/>} />
          <Route exact path='/products' element={<Products/>} />
          <Route exact path='/products/add-product' element={<AddProduct/>} />
          <Route exact path='/products/add-product/:product_id?' element={<AddProduct />} />
          <Route exact path='/category' element={<Category/>} />
          <Route exact path='/category/add-category' element={<AddCategory />} />
          <Route exact path='/category/add-category/:categoryId?' element={<AddCategory />} />
          <Route exact path='/orders' element={<Orders/>} />
          <Route exact path='/orders/order-detail' element={<OrderDetail/>} />
          <Route exact path='/orders/order-detail/:_id?' element={<OrderDetail/>} />
          <Route exact path='/customers' element={<Customers/>} />
          <Route exact path='/customers/add-customer' element={<AddCustomer/>} />
          <Route exact path='/customers/add-customer/:customerId?' element={<AddCustomer/>} />
          <Route exact path='/advertisement' element={<Advertisement/>} />
          <Route exact path='/advertisement/add-advertisement' element={<AddAdvertisement/>} />
          <Route exact path='/advertisement/add-advertisement/:adId?' element={<AddAdvertisement/>} />
          <Route exact path='/newsletter' element={<Newsletter/>} />
          <Route exact path='/contact-us' element={<ContactUs/>} />
        </Routes>
      </div>
    </Router>
    </AuthProvider>
  );
}

export default App;
