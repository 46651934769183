import React, { useEffect, useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authcontext';
import axios from 'axios';
import Navbar from '../../components/Navbar';
import Leftnav from '../../components/Leftnav';
import Table from '../order/OrderTable';
import SkeletonTable from '../TableSkeleton';
import {COMMON} from '../../constants/constant';

const headers = ['Id', 'Customer', 'Amount' ,'Status', 'Ordered Date', 'Action'];

const Orders = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [orders, setOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState('default'); // state for selected filter


  useEffect(() => {
    if(!user){
      navigate('/');
    }else{
      const fetchOrders = async () => {
        setLoading(true);
        try {
          const params = { page: currentPage, limit: 10 };
          if (filter !== 'default') {
            params.currency = filter === 'india' ? 'INR' : { $ne: 'INR' };
          }
  
          const response = await axios.get(`${process.env.REACT_APP_API_URL}manage-orders`, {
            params,
            headers: {
              "Authorization": `Bearer ${user}`,
              "Content-Type": "application/json"
            },
          });
          setOrders(response.data.orders);
          setTotalOrders(response.data.totalOrders);
        } catch (error) {
          console.error("There was an error fetching the orders!", error);
          setError(error);
        } finally {
          setLoading(false);
        }
      };
      
      fetchOrders();
}
  }, [currentPage,filter]);

  const deleteProduct = async (_id) => {
    if (!window.confirm('Are you sure you want to delete this order?')) {
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}manage-delete-api`, { _id, database: 'payment' }, {
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "application/json"
        },
      });
      alert('Order deleted successfully');
      window.location.reload();
    } catch (error) {
      alert('Error deleting order:', error.message);
    }
  };

  const fetchSearchData = async (term) => {

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}search-order?search=${term}`,{
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "application/json"
        },
      });
      setOrders(response.data); 
    } catch (err) {
      console.error('Failed to fetch data.');
    } 
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const totalPages = Math.ceil(totalOrders / 10);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const rows = orders.map(order => ([
    order._id,
    order.customer_name,
    order.amount || 0,
    order.status,
    formatDate(order.ordered_date),
    'Action',
    order.order_id,
  ]));

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <>
      <Navbar />
      <Leftnav />
      <div className='row-container'>
        <div className='page_title'>
          <div className='orderlist'>
          <h1 className="title">Orders List</h1>
          <div className="filter-container">
            <select value={filter} onChange={handleFilterChange}>
              <option value="default">Default</option>
              <option value="india">India</option>
              <option value="global">Global</option>
            </select>
          </div>
          </div>
          <Link className='add_btn' to="/orders/order-detail">ADD</Link>
        </div>
        {loading ? (
          <>
            <div className="skeleton-table">
                <div className="skeleton-search">
                  <div className="skeleton-search-bar"></div>
                </div>
            </div> 
            <SkeletonTable />
          </>
        ) :(
          <>
          <Table headers={headers} rows={rows} deleteProduct={deleteProduct} fetchSearchData={fetchSearchData}/>
          <div className='footer'>
            <div className='total_rows'>Total Orders: {totalOrders}</div>
            <div className='pagination'>
              <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>&lt;</button>
              <span>Page {currentPage} of {totalPages}</span>
              <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>&gt;</button>
            </div>
          </div>
        </>
        )}
      </div>
    </>
  );
};

export default Orders;
