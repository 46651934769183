import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/authcontext';
import Navbar from '../components/Navbar';
import Leftnav from '../components/Leftnav';
import Card from '../components/Cards';
import CardContainer from '../components/dashboard/CardTable';
import axios from 'axios';

const Dashboard = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(!user){
      navigate('/');
    }else{
      fetchData();
    }
  }, [user]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [totalCategories, totalProducts, totalUsers, totalOrders] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}category`),
        axios.get(`${process.env.REACT_APP_API_URL}product`),
        axios.get(`${process.env.REACT_APP_API_URL}customer`),
        axios.get(`${process.env.REACT_APP_API_URL}order`)
      ]);
console.log(totalProducts);
      setCardData([
        { id: 1, content: 'Total Categories', title: totalCategories.data.length, link: '/category' },
        { id: 2, content: 'Total Active Products', title: totalProducts.data.length, link: '/products' },
        { id: 3, content: 'Total Customers', title: totalUsers.data.length, link: '/customers' },
        { id: 4, content: 'Total Orders', title: totalOrders.data.length, link: '/orders'}
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };

  return (
    <>
      <Navbar />
      <Leftnav />
      <div className="row-container">
      <h1 className="page_title">Dashboard</h1>
      {loading ? (
        <div class="skeleton-table">
          <div class="skeleton-row">
            <div class="skeleton-card"></div>
            <div class="skeleton-card"></div>
            <div class="skeleton-card"></div>
            <div class="skeleton-card"></div>
          </div>
        </div>      ) :(
        <Card cards={cardData} />
      )}
      <CardContainer />
      </div>
    </>
  );
};

export default Dashboard;
