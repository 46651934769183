import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Table = ({ headers, rows, deleteAdvertisement }) => {
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(headers[0]); // Default sort by the first column
  const [sortedRows, setSortedRows] = useState(rows);

  useEffect(() => {
    setSortedRows(rows);
  }, [rows]);

  // Handle sorting by column
  const handleSort = (column) => {
    const newSortOrder = sortColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    setSortColumn(column);

    const columnIndex = headers.indexOf(column);
    const sorted = [...rows].sort((a, b) => {
      const aValue = a[columnIndex];
      const bValue = b[columnIndex];

      if (typeof aValue === 'string') {
        return newSortOrder === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else if (typeof aValue === 'number') {
        return newSortOrder === 'asc'
          ? aValue - bValue
          : bValue - aValue;
      }
      return 0;
    });

    setSortedRows(sorted);
  };

  return (
    <div style={{ overflowX: 'auto', width: '90%' }}>
      <table className='table-data'>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                className={index < 2 ? 'fixed-column' : ''}
                onClick={() => handleSort(header)}
                style={{ cursor: 'pointer' }}
              >
                {header} {sortColumn === header ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedRows.map((banner, rowIndex) => (
            <tr key={rowIndex}>
              {banner.map((cellData, cellIndex) => {
                if (cellIndex === 0) { 
                  return (
                    <td key={cellIndex} className={cellIndex < 2 ? 'fixed-column' : ''}>
                      {rowIndex + 1}
                    </td>
                  );
                } else if (headers[cellIndex] === 'Action') {
                  return (
                    <td key={cellIndex} className={cellIndex < 2 ? 'fixed-column' : ''}>
                      <Link className='edit_btn' to={`/advertisement/add-advertisement/${banner[0]}`}>
                        <img width='30px' height='30px' src='https://wallfleur-images.s3.ap-south-1.amazonaws.com/common/edit.svg' alt='edit_icon' />
                      </Link>
                      <button className='delete_btn' onClick={() => deleteAdvertisement(banner[0])}>
                        <img width='30px' height='30px' src='https://wallfleur-images.s3.ap-south-1.amazonaws.com/common/delete.png' alt='delete_icon' />
                      </button>
                    </td>
                  );
                } else {
                  return (
                    <td key={cellIndex} className={cellIndex < 2 ? 'fixed-column' : ''}>
                      {cellData}
                    </td>
                  );
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
