import React, { useEffect, useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authcontext';
import Navbar from '../../components/Navbar';
import Leftnav from '../../components/Leftnav';
import Table from './NewsletterTable';
import SkeletonTable from '../TableSkeleton';
import axios from 'axios';
import {COMMON} from '../../constants/constant';


const headers = ['Id', 'email', 'Added Date'];

const Newsletter = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [totalNewsletter, settotalNewsletter] = useState(0);
  const [mewsletter, setNewsletter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if(!user){
      navigate('/');
    }else{
      const fetchNewsletters = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}manage-newsletter`, {
            params: {
              page: currentPage,
              limit: 10
            },
            headers: {
              "Authorization": `Bearer ${user}`,
              "Content-Type": "application/json"
          },
          });
          setNewsletter(response.data.newsletter);
          settotalNewsletter(response.data.totalNewsletter);
          setLoading(false);
        } catch (error) {
          console.error("There was an error fetching the Categories!", error);
          setError(error);
          setLoading(false);
        }
      };

      fetchNewsletters();
    }
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= Math.ceil(totalNewsletter / 10)) {
      setCurrentPage(page);
    }
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const totalPages = Math.ceil(totalNewsletter / 10);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const rows = mewsletter.map(letter => [
    letter._id,
    letter.email,
    formatDate(letter.created_date),
  ]);

  return (
    <>
      <Navbar />
      <Leftnav />
      <div className="row-container">
        <div className='page_title'>
          <h1 className="title">Newsletter</h1>
        </div>
        {loading ? (
          <SkeletonTable />
        ) :(
          <>
            <Table headers={headers} rows={rows} />
            <div className='footer'>
              <div className='total_rows'>Total Newsletter: {totalNewsletter}</div>
              <div className='pagination'>
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>&lt;</button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>&gt;</button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Newsletter;
