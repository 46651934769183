import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import JSZip from 'jszip';
import { saveAs } from 'file-saver'; // To save the zip file on the user's machine
import fragile from '../assets/img/fragile.jpeg';


const generateInvoicePDF = async (order) => {

    const shippingHtml = `<div style="width: 900px; padding:100px;display:flex;justify-content:center;">
        <div style="width: 100%; height:100%; border: 2px solid black; padding: 20px; border-radius: 15px; font-family: Arial, sans-serif;">
            <table style="width: 100%;height:280px;padding:15px 0px;">
                <tr>
                    <td width=35% style="font-weight: bold;font-size: 34px;text-align: center;">SHIP FROM:</td>
                    <td width=65% style="font-weight: bold;font-size: 34px;">
                        WALLFLEUR<br>
                        H/no.-76 Forest Colony<br>
                        Dimapur, 797112,<br>
                        Nagaland, India.<br>
                        Phone no. : +916391113851.
                    </td>
                </tr>
            </table>

            <hr style="border: none; border-top: 1px solid black; margin: 10px 0;">

            <table style="width: 100%;height:600px;padding:15px 0px;">
                <tr>
                    <td width=35% style="text-align: center;">
                        <img src="${fragile}" alt="fragile" style="width:300px; margin-left:-20px;margin-right:20px;"/>
                    </td>
                    <td width=65% style="font-weight: bold;font-size: 34px;">SHIP TO:<br><br>
                        ${order.customer_name}<br><br>
                        ${order.address},<br>
                        ${order.city}, ${order.state},<br>
                        ${order.country} ${order.postalCode ? ', ' + order.postalCode : ''}.<br><br>
                        Phone no. : ${order.dialcode}${order.mobile}.<br>
                    </td>
                </tr>
            </table>

            <hr style="border: none; border-top: 1px solid black; margin: 10px 0;">

            <table style="width: 100%;height:180px;padding:10px 0px 20px 0px;">
                <tr style="background-color:#fff !important;">
                    <td width=35% style="font-weight: bold; font-size: 34px;">WEIGHT :</td>
                    <td width=65% style="font-size: 12px;"></td>
                </tr>
            </table>

            <div style="text-align: center; margin-top: 20px; font-size: 34px;">
                www.wallfleurthings.com
            </div>
        </div>
    </div>`;


    const hiddenContainer = document.createElement('div');
    hiddenContainer.style.position = 'fixed';
    hiddenContainer.style.top = '-10000px';
    hiddenContainer.innerHTML = shippingHtml;
    document.body.appendChild(hiddenContainer);

    return html2canvas(hiddenContainer).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft > 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
        }

        document.body.removeChild(hiddenContainer);

        return pdf;
    }).catch(error => {
        console.error("Error generating PDF:", error);
        throw error;
    });
};

export const downloadBulkShipping = async (orders) => {
    const zip = new JSZip();

    try {
        const pdfPromises = orders.map(async (order) => {
            const pdf = await generateInvoicePDF(order);
            const pdfBlob = pdf.output('blob'); // Generate the PDF as a blob
            zip.file(`shipping_label_${order.order_id}.pdf`, pdfBlob); // Add PDF to the zip
        });

        await Promise.all(pdfPromises); // Wait for all PDFs to be generated

        // Generate the zip file and save it
        zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, 'shipping_label.zip'); // Save the zip file
        });

    } catch (error) {
        console.error("Error during bulk invoice download:", error);
        alert('An error occurred. Please try again later.');
    }
};
