import React, { useState, useEffect } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authcontext';
import Navbar from '../../components/Navbar';
import Leftnav from '../../components/Leftnav';
import axios from 'axios';
import { CATEGORY } from '../../constants/constant';
import { toast } from 'react-toastify';

function AddCategory() {
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState('basic-info');
  const [categoryName, setCategoryName] = useState('');
  const [slug, setSlug] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [show_on_homepage, setshow_on_homepage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageloading, setImageLoading] = useState(false);

  useEffect(() => {
    if(!user){
      navigate('/');
    }else{
      if (categoryId) {
        fetchCategoryData(categoryId);
      } else {
        resetFormFields();
      }
    }
  }, [categoryId]);

  const fetchCategoryData = async (categoryId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}category-detail/${categoryId}`,{
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "application/json"
        },
      });
      const categoryData = response.data;
      setCategoryName(categoryData[0].name);
      setSlug(categoryData[0].slug);
      setIsChecked(categoryData[0].status);
      setshow_on_homepage(categoryData[0].show_on_homepage);
      setImagePreview(`${CATEGORY}${categoryData[0].image}`);
    } catch (error) {
      console.error('Error fetching category data:', error);
    }
  };

  const resetFormFields = () => {
    setCategoryName('');
    setSlug('');
    setIsChecked(false);
    setshow_on_homepage(false);
    setSelectedImage(null);
    setImagePreview(null);
  };

  const handleCategoryNameChange = (event) => {
    setCategoryName(event.target.value);
  };

  const handleSlugChange = (event) => {
    setSlug(event.target.value);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleShowOnHomepageChange = () => {
    setshow_on_homepage(!show_on_homepage);
  };

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setSelectedImage({
        file: imageFile,
        base64: reader.result,
        name: `${categoryId}_${imageFile.name}`
      });
      setImagePreview(reader.result);
    };

    reader.readAsDataURL(imageFile);
  };

  const handleBasicInfoSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!categoryName || !slug) {
      setLoading(false);
      alert('Please fill out all required fields');
      return;
    }

    const categoryData = {
      id: categoryId || '',
      name: categoryName,
      slug: slug,
      status: isChecked ? 1 : 0,
      show_on_homepage: show_on_homepage ? 1 : 0,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}addCategory`, categoryData, {
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "application/json"
        },
      });

      if (response.status === 201 || response.status === 200) {
        toast.success('Basic info saved successfully!', { position: 'top-right' });
        setActiveTab('image');
      } else {
        toast.error('Failed to save basic info', { position: 'top-right' });
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error saving basic info', { position: 'top-right' });
    }
    setLoading(false);
  };

  const handleImageSubmit = async (event) => {
    event.preventDefault();
    setImageLoading(true);

    if (!selectedImage) {
      setImageLoading(false);
      alert('Please select an image to upload');
      return;
    }

    const formData = new FormData();
    formData.append('id', categoryId || '');

    if (selectedImage.file) {
      formData.append('categoryImage', selectedImage.file);
      formData.append('image', selectedImage.name);
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}addImageCategory`, formData, {
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "multipart/form-data"
        },
      });

      if (response.status === 201 || response.status === 200) {
        toast.success('Category image uploaded successfully!', { position: 'top-right' });
        navigate('/category');
      } else {
        toast.error('Failed to upload image', { position: 'top-right' });
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error uploading image!', { position: 'top-right' });
    }
    setImageLoading(false);
  };

  const isBasicInfoComplete = categoryName && slug;

  return (
    <>
      <Navbar />
      <Leftnav />
      <div className="row-container">
        <div className="page_title">
          <h1 className="title">{categoryId ? 'Edit Category' : 'Add Category'}</h1>
        </div>
        <div className="tabs">
          <button
            className={`tab-button ${activeTab === 'basic-info' ? 'active' : ''}`}
            onClick={() => setActiveTab('basic-info')}
          >
            Basic Info
          </button>
          <button
            className={`tab-button ${activeTab === 'image' ? 'active' : ''}`}
            onClick={() => {
              if (isBasicInfoComplete) {
                setActiveTab('image');
              } else {
                alert('Please fill out the basic info before proceeding to the image tab.');
              }
            }}
            disabled={!isBasicInfoComplete}
          >
            Image
          </button>
        </div>
        {activeTab === 'basic-info' && (
          <form className="category_form" onSubmit={handleBasicInfoSubmit}>
            <div className="form-group">
              <label htmlFor="categoryName">Category Name*:</label>
              <input
                type="text"
                id="categoryName"
                value={categoryName}
                placeholder="Category Name"
                onChange={handleCategoryNameChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="slug">Category Slug*:</label>
              <input
                type="text"
                id="slug"
                value={slug}
                placeholder="Slug"
                onChange={handleSlugChange}
                required
              />
            </div>
            <div className="form-group">
              <label>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                Show on Website
              </label>
            </div>
            <div className="form-group">
              <label>
                <input
                  type="checkbox"
                  checked={show_on_homepage}
                  onChange={handleShowOnHomepageChange}
                />
                Show on Homepage
              </label>
            </div>
            <button type="submit" disabled={loading}>
              {categoryId ? (loading ? "Updating..." : "Update") : (loading ? "Submitting..." : "Submit")}
              </button>
          </form>
        )}
        {activeTab === 'image' && (
          <form className="category_form" onSubmit={handleImageSubmit}>
            <div className="image-tab">
              <div className="form-group">
                <label htmlFor="imageUpload">Category Image:</label>
                <input
                  type="file"
                  id="imageUpload"
                  onChange={handleImageChange}
                  disabled={!isBasicInfoComplete}
                />
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{ width: '200px', height: '200px', marginTop: '10px', marginBottom: '20px' }}
                  />
                )}
              </div>
              <button type="submit" disabled={imageloading}>
                {imageloading ? "Uploading..." : "Upload"}
              </button>
            </div>
          </form>
        )}
      </div>
    </>
  );
}

export default AddCategory;