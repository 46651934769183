import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authcontext';
import { toast } from 'react-toastify';
import Navbar from '../../components/Navbar';
import axios from 'axios';
import Leftnav from '../../components/Leftnav';
import { PRODUCT } from '../../constants/constant';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 


function AddProduct() {
  const { product_id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [productName, setProductName] = useState('');
  const [description, setDescription] = useState('');
  const [slug, setSlug] = useState('');
  const [note, setNote] = useState('');
  const [quantity, setQuantity] = useState('');
  const [maxquantity, setMaxQuantity] = useState('');
  const [inrprice, setInrPrice] = useState('');
  const [usdprice, setUsdPrice] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [show_on_website, setshow_on_website] = useState(false);
  const [show_on_homepage, setshow_on_homepage] = useState(false);
  const [preOrder, setPreOrder] = useState(false);
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);
  const [image5, setImage5] = useState(null);
  const [image6, setImage6] = useState(null);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState('basicInfo');
  const [basicInfoCompleted, setBasicInfoCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageloading, setImageLoading] = useState(false);

  useEffect(() => {
    if (!user) {
      navigate('/');
    } else {
      if (product_id !== undefined) {
        fetchProductData(product_id);
        setBasicInfoCompleted(true);

      } else {
        resetFormFields();
      }
    }
  }, [user, product_id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}category-id`, {
          method: 'GET',
          headers: {
            "Authorization": `Bearer ${user}`,
            "Content-Type": "application/json"
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const resetFormFields = () => {
    setProductName('');
    setDescription('');
    setSlug('');
    setNote('');
    setQuantity('');
    setMaxQuantity('');
    setInrPrice('');
    setUsdPrice('');
    setCategory('');
    setSubCategory('');
    setshow_on_website(false);
    setshow_on_homepage(false);
    setPreOrder(false);
    setImage1(null);
    setImage2(null);
    setImage3(null);
    setImage4(null);
    setImage5(null);
    setImage6(null);
    setImagePreviews([]);
  };

  const fetchProductData = async (productId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}product-detail/${productId}`, {
        method: 'GET',
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "application/json"
        },
      });
      if (response.ok) {
        const productData = await response.json();
        setProductName(productData[0].name);
        setDescription(productData[0].description);
        setSlug(productData[0].slug);
        setNote(productData[0].note);
        setQuantity(productData[0].quantity);
        setMaxQuantity(productData[0].maxquantity);
        setInrPrice(productData[0].inrprice);
        setUsdPrice(productData[0].usdprice);
        setCategory(productData[0].category_id);
        setSubCategory(productData[0].sub_category_id);
        setshow_on_website(productData[0].show_on_website);
        setshow_on_homepage(productData[0].show_on_homepage);
        setPreOrder(productData[0].preorder);
        setImage1(productData[0].image1);
        setImage2(productData[0].image2);
        setImage3(productData[0].image3);
        setImage4(productData[0].image4);
        setImage5(productData[0].image5);
        setImage6(productData[0].image6);
        const newImagePreviews = [
          productData[0].image1,
          productData[0].image2,
          productData[0].image3,
          productData[0].image4,
          productData[0].image5,
          productData[0].image6
        ].filter(image => image !== null) // Only keep non-null values

        // Map each image to the format you need
        const imageObjects = newImagePreviews.map((imageUrl, index) => ({
          file: null, // No file for existing images
          name: `image${index + 1}`, // You can use the image name or a unique identifier
          preview: `${PRODUCT}${imageUrl}` // Full image URL (preview)
        }));
        setImagePreviews(imageObjects);
      } else {
        console.error('Failed to fetch product data');
      }
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  };

  const handleProductNameChange = (event) => {
    setProductName(event.target.value);
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  const handleSlugChange = (event) => {
    setSlug(event.target.value);
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const handleMaxQuantityChange = (event) => {
    setMaxQuantity(event.target.value);
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const handleInrPriceChange = (event) => {
    setInrPrice(event.target.value);
  };

  const handleUsdPriceChange = (event) => {
    setUsdPrice(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleSubCategoryChange = (event) => {
    setSubCategory(event.target.value);
  };

  const handleCheckboxChange = () => {
    setshow_on_website(!show_on_website);
  };

  const handleHomaPageCheckboxChange = () => {
    setshow_on_homepage(!show_on_homepage);
  };

  const handlePreOrderCheckboxChange = () => {
    setPreOrder(!preOrder);
  };



  const handleImageChange = (index, event) => {
    const imageFile = event.target.files[0];
    if (!imageFile) return;

    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;
      const newImagePreviews = [...imagePreviews];
      newImagePreviews[index] = {
        file: imageFile,
        name: `${product_id}_${imageFile.name}`,
        preview: base64String
      };
      setImagePreviews(newImagePreviews);
    };

    reader.readAsDataURL(imageFile);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!productName || !description || !slug || !quantity || !maxquantity || !inrprice || !usdprice || !category || !subCategory) {
      setLoading(false);
      alert('Please fill out all required fields');
      return;
    }

    const productData = {
      id: product_id || '',
      name: productName,
      description: description,
      slug: slug,
      note: note,
      quantity: quantity,
      maxquantity: maxquantity,
      inrprice: inrprice,
      usdprice: usdprice,
      category_id: category,
      sub_category_id: subCategory,
      show_on_website: show_on_website ? 1 : 0,
      show_on_homepage: show_on_homepage ? 1 : 0,
      preorder: preOrder ? 1 : 0
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}addProduct`, JSON.stringify(productData), {
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "application/json"
        },
      });
      if (response.status === 200) {
        console.log(response.data);
        toast.success(response.data.message, { position: 'top-right' });
        setBasicInfoCompleted(true);
        setActiveTab('images');
        navigate(`/products/add-product/${response.data.product.id}`);
      } else {
        toast.error('Failed to add product', { position: 'top-right' });
      }
    } catch (error) {
      console.log(error);
      toast.error('Error adding product', { position: 'top-right' });
    }
    setLoading(false);
  };

  const handleImageSubmit = async (event) => {
    event.preventDefault();
    setImageLoading(true);

    const formData = new FormData();
    formData.append('product_id', product_id || '');


    imagePreviews.forEach((image, index) => {
      if (image.file) {
        formData.append(`image${index + 1}`, image.file, image.name || `image${index}`);
      }
    });

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}addProductImages`, formData, {
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "multipart/form-data"
        },
      });

      if (response.status === 200) {
        toast.success('Images added successfully!', { position: 'top-right' });
        navigate('/products');
      } else {
        toast.error('Failed to add images', { position: 'top-right' });
      }
    } catch (error) {
      toast.error('Error adding images', { position: 'top-right' });
    }
    setImageLoading(false);
  };


  const handleTabClick = (tab) => {
    if (tab === 'images' && !basicInfoCompleted) {
      alert('Please complete the Basic Info form first.');
      return;
    }
    setActiveTab(tab);
  };

  return (
    <>
      <Navbar />
      <Leftnav />
      <div className="row-container">
        <div className='page_title'>
          <h1 className="title">{product_id ? 'Edit Product' : 'Add Product'}</h1>
        </div>
        <div className='tabs'>
          <button className={activeTab === 'basicInfo' ? 'active' : ''} onClick={() => handleTabClick('basicInfo')}>Basic Info</button>
          <button className={activeTab === 'images' ? 'active' : ''} onClick={() => handleTabClick('images')}>Images</button>
        </div>
        {activeTab === 'basicInfo' && (
          <form className='product_form' onSubmit={handleSubmit}>
            <div className='form-container'>
              <div className='form-group'>
                <label htmlFor="productName">Product Name*:</label>
                <input
                  type="text"
                  id="productName"
                  value={productName}
                  placeholder='Product Name'
                  onChange={handleProductNameChange}
                  required
                />
              </div>
              <div className='form-group'>
                <label htmlFor="slug">Product Slug*:</label>
                <input
                  type="text"
                  id="slug"
                  value={slug}
                  placeholder='Slug'
                  onChange={handleSlugChange}
                  required
                />
              </div>
            </div>
            <div className='form-container'>
              <div className='form-group'>
                <label htmlFor="dimension">note*:</label>
                <input
                  type="text"
                  id="note"
                  value={note}
                  placeholder='Note'
                  onChange={handleNoteChange}
                />
              </div>
              <div className='form-group'>
                <label htmlFor="quantity">Quantity*:</label>
                <input
                  type="text"
                  id="quantity"
                  value={quantity}
                  placeholder='Quantity'
                  onChange={handleQuantityChange}
                  required
                />
              </div>
            </div>
            <div className='form-container'>
              <div className='form-group'>
                <label htmlFor="inrprice">Price (in INR)*:</label>
                <input
                  type="text"
                  id="inrprice"
                  value={inrprice}
                  placeholder='Price'
                  onChange={handleInrPriceChange}
                  required
                />
              </div>
              <div className='form-group'>
                <label htmlFor="usdprice">Price (in USD)*:</label>
                <input
                  type="text"
                  id="usdprice"
                  value={usdprice}
                  placeholder='Price'
                  onChange={handleUsdPriceChange}
                  required
                />
              </div>
            </div>
            <div className='form-group'>
              <label htmlFor="maxquantity">Max Quantity *:</label>
              <input
                type="text"
                id="maxquantity"
                value={maxquantity}
                placeholder='maxquantity'
                onChange={handleMaxQuantityChange}
                required
              />
            </div>
            <div className='form-group'>
              <label htmlFor="description">Description*:</label>
              {/* React Quill for the Description Field */}
              <ReactQuill
                value={description}
                onChange={handleDescriptionChange} // Use ReactQuill's onChange to update the state
                placeholder="Enter product description"
                theme="snow" // You can change the theme here (snow is a common theme)
                modules={{
                  toolbar: [
                    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    ['bold', 'italic', 'underline'],
                    [{ 'align': [] }],
                    ['link', 'image'],
                    ['blockquote', 'code-block'],
                  ],
                }}
              />
            </div>
            <div className='form-container'>
              <div className='form-group'>
                <label htmlFor="category">Category*:</label>
                <select id="category" value={category} onChange={handleCategoryChange} required>
                  <option value="">Select Category</option>
                  {categories.map(category => (
                    <option key={category.id} value={category.id}>{category.name}</option>
                  ))}
                </select>
              </div>
              <div className='form-group'>
                <label htmlFor="subCategory">Sub Category:</label>
                <select id="subCategory" value={subCategory} onChange={handleSubCategoryChange}>
                  <option value="">Select Sub Category</option>
                  {categories.map(category => (
                    <option key={category.id} value={category.id}>{category.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className='form-container'>
              <div className='form-group'>
                <label>
                  <input
                    type="checkbox"
                    checked={show_on_website}
                    onChange={handleCheckboxChange}
                  />
                  Show on Website
                </label>
              </div>
              <div className='form-group'>
                <label>
                  <input
                    type="checkbox"
                    checked={show_on_homepage}
                    onChange={handleHomaPageCheckboxChange}
                  />
                  Show on Homepage
                </label>
              </div>
              <div className='form-group'>
                <label>
                  <input
                    type="checkbox"
                    checked={preOrder}
                    onChange={handlePreOrderCheckboxChange}
                  />
                  PreOrder
                </label>
              </div>
            </div>

            <button type="submit" disabled={loading}>
              {product_id ? (loading ? "Updating..." : "Update") : (loading ? "Submitting..." : "Submit")}
            </button>
          </form>
        )}
        {activeTab === 'images' && (
          <div className='image-upload-section'>
            <div className='form-container'>
              {Array.from({ length: 6 }, (_, index) => (
                <>
                  <div className='form-group' key={index}>
                    <label htmlFor={`imageUpload${index + 1}`} ></label>
                    <input
                      type="file"
                      id={`imageUpload${index + 1}`}
                      onChange={(e) => handleImageChange(index, e)}
                    />
                  </div>
                  <div className='form-group' key={index}>

                    {imagePreviews[index] && imagePreviews[index].preview && (
                      <div className="image-preview">
                        <img
                          src={imagePreviews[index].preview} // Use the .preview property for base64 preview or existing image
                          alt={`Preview ${index + 1}`}
                          style={{ width: '60px', height: '60px' }}
                        />
                      </div>
                    )}
                  </div>
                </>
              ))}
            </div>
            <button type="submit" disabled={imageloading} onClick={handleImageSubmit}>
              {imageloading ? "Uploading..." : "Upload"}
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default AddProduct;
